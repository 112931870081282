<template>
<div class="common_sub_container">
  <h2>EXPRESS AND REGISTERED POST REGISTER</h2>
  <section class="sections_to_hide">
    <div class="form_section1">
      <table class="scoped_cell_width_adj">
        <tr>
          <td>
            <div class="label_and_element_wrapper">
              <label>
                Choose Period
                <span></span>
              </label>
              <flat-pickr
                class="date_for_pos_fixed"
                v-model="daterange"
                :config="configDate"
                placeholder="Date"
                name="birthdate"
                @on-close="dateChanged">
                </flat-pickr>
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <label>
              Document Types
                <span></span>
              </label>
                <select v-model="post_data.postal_document_type_id">
                  <option value="">All</option>
                  <option v-for="(postaldocument,i) in postaldocuments" :key="'postaldocuments'+i" :value="postaldocument.id">{{postaldocument.postal_document_type}}</option>
                </select>
            </div>
        </td>
        </tr>
        <tr>
          <td>
            <div class="label_and_element_wrapper">
              <label>
               Client category
                <span></span>
              </label>
                <select v-model="post_data.client_category">
                  <option value="">All</option>
                  <option value="kwm">KWM</option>
                  <option value="base client">Base</option>
                  <option value="personal">Personal</option>
                </select>
            </div>
          </td>
          <td v-if="'kwm' == post_data.client_category">
            <div class="label_and_element_wrapper">
              <label>
               Client branches
                <span></span>
              </label>
                <select v-model="post_data.client_category_branch">
                  <option value="">All</option>
                  <option
                    v-for="(branch,i) in branches"
                    :key="'branches'+i"
                    :value="branch.name"
                  >{{branch.name}}</option>
                </select>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <a class="link_bt bt_save" @click="fetchReport">Search</a>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </section>
  <section v-if="show_data" class="reports_to_print">
    <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
      <header>
        <h3>Express and Registered Post Register {{ auth().user_type != 'super_admin' ? ` - ${auth().branch}` : '' }}</h3>
        <div class="right_part">
          <a title="Download" ref="download" @click="downloadReport">
            <i class="material-icons">arrow_drop_down_circle</i>
          </a>
          <a title="Print" @click="printRecord">
            <i class="material-icons">print</i>
          </a>
        </div>
      </header>
      <div class="despatch_summary_table_container">
        <table>
          <thead>
            <tr>
              <td><b>Date</b></td>
              <td><b>Sender</b></td>
              <td><b>Receiver</b></td>
              <td><b>Matter Code</b></td>
              <td><b>GL Code</b></td>
              <td><b>Tracking Number</b></td>
              <td><b>Bag Size</b></td>
              <td><b>Cost</b></td>
              <td><b>Request Origination</b></td>
              <td><b>Mailroom Staff</b></td>
            </tr>
          </thead>
          <tbody>
            <template  v-for="(data,i) in reports" >
              <template  v-if="data.references.length">
                <template v-for="reference in data.references">
                <tr>
                  <td>
                    {{data.issue_by_date | formatDate}}
                    <!-- Date -->
                  </td>
                  <td>
                    {{ data.requester_name }}
                    <!-- <b>Sender</b> -->
                  </td>
                  <td>
                    {{ data.receiver_name }}
                    <!-- <b>Receiver</b> -->
                  </td>
                  <td>
                    {{ data.matter_number ? data.matter_number.matter_number : '' }}
                    <!-- <b>Matter Code</b> -->
                  </td>
                  <td>
                    {{ (data.custom_code_value && data.custom_code == 'GL Code') ? glcodeGenerate(data.custom_code_value) : data.custom_code_value }}
                    <!-- <b>GL Code</b> -->
                  </td>
                  <td>
                    {{ reference.reference_number }}
                    <!-- Tracking Number -->
                  </td>
                  <td>
                    {{ data.postal_document_type ? data.postal_document_type.postal_document_name : '' }}
                    <!-- Bag Size -->
                  </td>
                  <td>
                    {{ data.unit_price }}
                    <!-- Cost -->
                  </td>
                  <td>
                    {{ data.request_reference }}
                    <!-- <b>Request Origincation</b> -->
                  </td>
                  <td>
                    {{ data.issued_by }}
                    <!-- <b>Mailroom Staff</b> -->
                  </td>
                </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td>
                    {{data.issue_by_date | formatDate}}
                    <!-- Date -->
                  </td>
                  <td>
                    {{ data.requester_name }}
                    <!-- <b>Sender</b> -->
                  </td>
                  <td>
                    {{ data.receiver_name }}
                    <!-- <b>Receiver</b> -->
                  </td>
                  <td>
                    {{ data.matter_number ? data.matter_number.matter_number : '' }}
                    <!-- <b>Matter Code</b> -->
                  </td>
                  <td>
                    {{ (data.custom_code_value && data.custom_code == 'GL Code') ? glcodeGenerate(data.custom_code_value) : data.custom_code_value }}
                    <!-- <b>GL Code</b> -->
                  </td>
                  <td>
                    {{ data.reference_number }}
                    <!-- Tracking Number -->
                  </td>
                  <td>
                    {{ data.postal_document_type ? data.postal_document_type.postal_document_name : '' }}
                    <!-- Bag Size -->
                  </td>
                  <td>
                    {{ data.unit_price }}
                    <!-- Cost -->
                  </td>
                  <td>
                    {{ data.request_reference }}
                    <!-- <b>Request Origincation</b> -->
                  </td>
                  <td>
                    {{ data.issued_by }}
                    <!-- <b>Mailroom Staff</b> -->
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
  </section>
</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
  data() {
    return {
      daterange: [
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      ],
      postaldocuments:[],
      show_data:false,
      api_url: "",
      post_data: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
        provider_id:"",
        service_providers_id:"",
        postal_document_type_id:"",
        code_value:"",
        matter_number:"",
        issued:"",
        via:"issue_by_date",
        client_category:"",
        client_category_branch:"",
      },
      reports:[],
      configDate: {
        mode: "range",
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
    }
  },
  components:{
    flatPickr,
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatDateCode(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYYMMDD')
    },
    nextMonthDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).add(1, 'M').format('YYYYMMDD')
    },
    yearMonth(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYYMM')
    },
    formatTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('LT')
    },
  },
  async created() {
    this.$emit("breadCrumbData", ["Reports", "Express and Registered Post Register"])
    this.api_url = process.env.VUE_APP_API_URL;
    await this.fetchPostalDocument()
    await this.fetchBranch()
  },
  methods: {
    glcodeGenerate(dataGlCode) {
      // let glcode = ''

      // glcode += "21100-000000-"
      // glcode += dataGlCode.section + "-"
      // glcode += dataGlCode.department + "-"
      // glcode += dataGlCode.office + "-"
      // glcode += dataGlCode.entity + "-"
      // glcode += "000"

      return `21100-000000-${dataGlCode}`;
    },
    async fetchReport() {
      const url = "/api/reports/get-express-and-registered-report-post-register"
      this.reports = []
      
      try {
        const response = await this.axios.post(url, this.post_data)
        this.show_data = true
        this.reports = response.data.data
      } catch (e) {

      }     
    },
    fetchBranch() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.branches = response.data.branches;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchPostalDocument(){
      this.axios.get("/api/postaldocument")
        .then(response => {
            this.postaldocuments = response.data.postal_document_types;
        })
        .catch(error =>{
            this.toast.error();
        });
    },
    dateChanged(daterange) {
      this.post_data.from = moment(daterange[0]).format("YYYY-MM-DD");
      this.post_data.to = moment(daterange[1]).format("YYYY-MM-DD");
    },
    downloadReport() {
      let method = "POST";
      let input;
      let form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute(
        "action",
        this.api_url +
          "/api/reports/get-express-and-registered-report-post-register/download?token=" +
          localStorage.token
      );
      Object.keys(this.post_data).forEach(item => {
        input = document.createElement("input");
        input.type = "text";
        input.name = item;
        input.value = this.post_data[item];
        form.appendChild(input);
      });
      this.$refs.download.appendChild(form);
      form.submit();
      this.$refs.download.removeChild(form);
    },
    printRecord() {
      window.print();
    }
  }
}
</script>

<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: unset;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>